import Vue from 'vue';
import Router from 'vue-router';
import HomeCHView from '../views/HomeCH.vue';
import HomeEnView from '../views/HomeEn.vue';
import HomeINDView from '../views/HomeIND.vue';

Vue.use(Router);

export const constantRoutes = [
  {
    path: '/',
    name: 'homeIND',
    component: HomeINDView,
  },
  {
    path: '/EN',
    name: 'homeEn',
    component: HomeEnView,
  },
  {
    path: '/CH',
    name: 'homeCH',
    component: HomeCHView,
  },
  // {
  //   path: '/planDetail',
  //   name: 'planDetail',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/PlanDetail.vue'),
  // },
  // {
  //   path: '/productDetail/1',
  //   name: 'productDetail',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/DoublePrevention.vue'),
  // }
];

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
