<template>
  <div class="home">
    <!-- header -->
    <div class="header" :class="scrollTop > 10 ? 'header-scrolled' : ''">
      <div class="logo">
        <img v-if="!isThird" class="img" src="@/assets/logo.png" />
        <span v-if="!isThird">Vape Pertama</span>
      </div>
      <div class="header-right">
        <nav id="nav-menu-container" :class="isThird ? 'white' : ''">
          <ul class="nav-menu">
            <li><a href="#.html">Beranda</a></li>
            <li><a href="#gywm">Tentang Kami</a></li>
            <li><a href="#cp">Produk</a></li>
            <li><a href="#lxwm">Hubungi Kami</a></li>
          </ul>
        </nav>
        <div class="paste-button">
          <button class="button">{{ language }} ▼</button>
          <div class="dropdown-content">
            <div
              v-for="item in languageList"
              :key="item.id"
              @click="changeLanguage(item)"
            >
            {{item.text}}</div>
          </div>
        </div>
        <button
          class="btn-buy"
          @click="goPath('https://www.tokopedia.com/vapepertama')"
        >
          Kunjungi Toko
        </button>
      </div>
      <div class="menu">
        <!-- <i
          class="el-icon-menu"
          :class="isThird ? 'white' : ''"
          @click="isLittleMenu = !isLittleMenu"
        ></i> -->
        <i
          class="el-icon-sell"
          :class="isThird ? 'white' : ''"
          @click="goPath('https://www.tokopedia.com/vapepertama')"
        ></i>
        <i
          class="el-icon-caret-bottom"
          :class="isThird ? 'white' : ''"
          v-if="!isChangeLag"
          @click="isChangeLag = !isChangeLag"
        ></i>
        <i
          v-else
          class="el-icon-caret-top"
          @click="isChangeLag = !isChangeLag"
        ></i>
        <ul class="menu-ul" v-show="isLittleMenu">
          <li><a href="#.html">Beranda</a></li>
          <li><a href="#gywm">Tentang Kami</a></li>
          <li><a href="#cp">Produk</a></li>
          <li><a href="#lxwm">Hubungi Kami</a></li>
        </ul>
        <ul class="menu-ul" v-show="isChangeLag">
          <li @click="changeLag('/')">Bahasa Indonesia</li>
          <li @click="changeLag('/EN')">English</li>
          <li @click="changeLag('/CH')">简体中文</li>
        </ul>
      </div>
    </div>

    <!-- banner -->
    <div class="banner">
      <el-carousel
        :height="carouselHeight"
        :key="carouselKey"
        @change="changeBanner"
      >
        <el-carousel-item class="item1"> </el-carousel-item>
        <el-carousel-item class="item2"> </el-carousel-item>
        <el-carousel-item class="item3"> </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 关于我们 -->
    <div class="us" id="gywm">
      <div class="us-content">
        <div class="title">
          <h2>PT Yooz Jaya Indonesia</h2>
        </div>
        <div class="text">
          <h2>
            merupakan perusahaan distributor yang menyediakan rokok elektrik
          </h2>
          <span
            >dengan kualitas tinggi seperti POD Vape, Liquid dan lain
            sebagainya.
          </span>
        </div>
        <div class="text">
          <p>
            Perusahaan didirikan pada Agustus 2023 dengan visi misi yang
            bertujuan untuk menyediakan produk pilihan nomor satu dengan
            penawaran terbaik.
          </p>
        </div>
      </div>
      <div class="us-img">
        <img class="img" src="@/assets/image/us-IND.png" alt="" />
      </div>
    </div>
    <!-- 产品视频 -->
    <div class="video">
      <el-carousel
        ref="carousel"
        indicator-position="none"
        :autoplay="false"
        :autoplay-hover-pause="true"
        @change="carouselChange"
        arrow="always"
      >
        <div v-if="vidData && vidData.length > 0">
          <el-carousel-item v-for="(item, index) in vidData" :key="item.src">
            <video
              ref="videos"
              class="tsgz-video"
              :controls="videoIndex == index"
              :src="item.src"
              type="video/mp4"
              :autoplay="videoIndex == index"
              muted
              @ended="playNextVideo(index)"
              width="100%"
              height="100%"
            >
              Tab video tidak didukung untuk peramban anda.
            </video>
          </el-carousel-item>
        </div>
        <el-empty
          description="暂无视频"
          v-else
          style="height: 225px"
          :image-size="40"
          image=""
        ></el-empty>
      </el-carousel>
    </div>
    <!-- 产品 -->
    <div class="product" id="cp">
      <h1>Produk</h1>
      <div class="top">
        <div class="item">
          <div class="card prd-1"></div>
          <p class="text-body">UWU Infinity Vape Pods Device - Black</p>
        </div>
        <div class="item">
          <div class="card prd-2"></div>
          <p class="text-body">UWU Infinity Vape Pods Device - Blue</p>
        </div>
        <div class="item">
          <div class="card prd-3"></div>
          <p class="text-body">UWU Infinity Vape Pods Device - Pink</p>
        </div>
        <div class="item">
          <div class="card prd-4"></div>
          <p class="text-body">UWU Infinity Vape Pods Device - White</p>
        </div>
        <div class="item">
          <div class="card prd-5"></div>
          <p class="text-body">KangerTech Elipen Device</p>
        </div>
        <div class="item">
          <div class="card prd-6"></div>
          <p class="text-body">KangerTech LiM Device</p>
        </div>
        <div class="item">
          <div class="card prd-7"></div>
          <p class="text-body">KangerTech Evod 3000</p>
        </div>
        <div class="item">
          <div class="card prd-8"></div>
          <p class="text-body">KangerTech Pro Vito Device</p>
        </div>
        <div class="item">
          <div class="card prd-9"></div>
          <p class="text-body">KangerTech Slibox Device</p>
        </div>
        <div class="item">
          <div class="card prd-10"></div>
          <p class="text-body">KangerTech Kanger 7000</p>
        </div>
        <div class="item">
          <div class="card prd-11"></div>
          <p class="text-body">KangerTech Tri Box Pod Device</p>
        </div>
      </div>
      <div class="bottom">
        <div
          class="btn"
          @click="goPath('https://www.tokopedia.com/vapepertama')"
        >
          Beli sekarang
        </div>
      </div>
    </div>

    <!-- 联系我们 -->
    <div class="connection" id="lxwm">
      <div class="title">
        <h1>Hubungi Kami</h1>
      </div>
      <div class="msg">
        <img src="@/assets/image/lxwm/icon-3.png" alt="" />
        <span>Tel：+62 852 1066 5688</span>
      </div>
      <div class="msg">
        <img src="@/assets/image/lxwm/icon-4.png" alt="" />
        <span>Email：vapepertama@gmail.com</span>
      </div>
      <div class="msg">
        <img src="@/assets/image/lxwm/icon-2.png" alt="" />
        <span
          >Alamat Kantor：Jl. Kemuning No. 62 Blok B XVII Kav. 649 Phase V Jenis OKW,Desa/Kelurahan Jatipulo, Kec. Pal Merah, Kota Adm. Jakarta Barat, Provinsi DKI Jakarta,11430</span
        >
      </div>
      <img src="@/assets/image/map.png" alt="" />
    </div>

    <!-- footer -->
    <div class="footer">
      <div
        class="item"
        @click="goPath('https://www.tokopedia.com/vapepertama')"
      >
        <div class="title">Marketplace Kami</div>
        <img class="icon" src="@/assets/image/footer/shop.png" alt="" />
        <div class="text">Tokopedia:</div>
        <div class="text">Vape Pertama</div>
      </div>
      <div class="item">
        <div class="title">Informasi</div>
        <div class="text"><a href="#gywm">Tentang Kami</a></div>
        <div class="text"><a href="#cp">Produk</a></div>
        <div class="text"><a href="#lxwm">Hubungi Kami</a></div>
      </div>
      <div class="item">
        <div class="title">Ikuti kami di media sosial</div>
        <div
          class="text"
          @click="goPath('https://www.instagram.com/vapepertama/')"
        >
          <img class="icon" src="@/assets/image/footer/ins.png" alt="" />
          <span class="i">vapepertama</span>
        </div>
        <div
          class="text"
          @click="goPath('https://www.tiktok.com/@vapepertama')"
        >
          <img class="icon" src="@/assets/image/footer/tiktok.png" alt="" />
          <span class="i">vapepertama</span>
        </div>
        <div
          class="text"
          @click="goPath('https://www.facebook.com/vapepertama')"
        >
          <img class="icon" src="@/assets/image/footer/facebook.png" alt="" />
          <span class="i">vapepertama</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      scrollTop: null,
      dialogVisible: false,
      isThird: false,
      bannerIndex: 0,
      isLittleMenu: false,
      isChangeLag: false,
      language: 'Bahasa Indonesia',
      languageList: [
        { id: 0, path: '/', text: 'Bahasa Indonesia' },
        { id: 1, path: '/En', text: 'English' },
        { id: 2, path: '/CH', text: '中文' },
      ],
      carouselHeight: '',
      carouselKey: 0,
      vidData: [
        { src: require('../assets/video/video-1.mp4') },
        { src: require('../assets/video/video-2.mp4') },
        { src: require('../assets/video/video-3.mp4') },
        { src: require('../assets/video/video-4.mp4') },
        { src: require('../assets/video/video-5.mp4') },
      ],
      videoIndex: 0,
    };
  },
  watch: {
    scrollTop: {
      handler(val) {
        // console.log(val);
        if (val > 10) {
          this.isThird = false;
        } else if (val < 10 && this.bannerIndex == '2') {
          this.isThird = true;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.updateCarouselHeight()
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.updateCarouselHeight);
  },
  methods: {
    changeLag(path){
      this.$router.push(path)
    },
    //获取滚动高度
    handleScroll() {
      this.$nextTick(() => {
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        this.scrollTop = scrollTop;
      });
    },
    goPath(url) {
      window.open(url, '_blank');
    },
    changeBanner(val) {
      this.bannerIndex = val;
      if (val == '2' && this.scrollTop < 10) {
        this.isThird = true;
      } else {
        this.isThird = false;
      }
    },
    changeLanguage(item) {
      this.language = item.text;
      this.$router.push(item.path)
    },
    updateCarouselHeight() {
      let width = window.innerWidth;
      if (0 < width && width < 420) {
        this.carouselHeight = '300px';
        this.carouselKey++;
      } else if (420 < width && width < 850) {
        this.carouselHeight = '500px';
        this.carouselKey++;
      } else {
        this.carouselHeight = '100vh';
        this.carouselKey++;
      }
    },
    carouselChange(index) {
      const videos = this.$refs.videos;
      videos.forEach((video) => {
        video.currentTime = 0; // 将视频回到起始时间
        video.pause(); // 暂停视频播放
      });
      this.videoIndex = index;
      // videos[index].play();
    },
    playNextVideo(index) {
      const videos = this.$refs.videos;
      let nextIndex = index;
      if (index < this.vidData.length - 1) {
        nextIndex = nextIndex + 1;
      } else {
        nextIndex = 0;
      }
      const carousel = this.$refs.carousel;
      carousel.setActiveItem(nextIndex);
      const nextVideo = videos[nextIndex];
      videos.forEach((video) => {
        video.pause();
        video.currentTime = 0;
      });
      setTimeout(() => {
        nextVideo.play();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
//头部导航
@import '~@/assets/styles/header.scss';
//banner
@import '~@/assets/styles/banner.scss';
//关于我们
@import '~@/assets/styles/us.scss';
//产品
@import '~@/assets/styles/product.scss';
//联系我们
@import '~@/assets/styles/contact.scss';
//footer
@import '~@/assets/styles/footer.scss';
//适配
@import '~@/assets/styles/media.scss';
</style>
